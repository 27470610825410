import { Routes } from '@angular/router';

import { hasNotLoginGuard, isEnrollGuard, isLoginGuard } from '@core/guards';
import { viewPermissionResolverFn } from './core/resolvers';
import { isWebGuard } from './core/guards/is-web.guard';
import { isAdminGuard } from './core/guards/is-admin.guard';
import { isEnrollCompleteGuard } from './core/guards/is-enroll-complete.guard';

export const routes: Routes = [
  {
    path: '',
    canActivate: [hasNotLoginGuard],
    loadComponent: () =>
      import(
        './core/layouts/auth-layouts/auth-layout/auth-layout.component'
      ).then((c) => c.AuthLayoutComponent),
    loadChildren: () =>
      import('./pages/auth-pages/auth-pages.routes').then(
        (r) => r.authPagesRoutes
      ),
  },
  {
    path: '',
    title: 'Top Notch Dezigns® Hub',
    canActivate: [isLoginGuard, isAdminGuard, isEnrollCompleteGuard],
    resolve: { data: viewPermissionResolverFn },
    loadComponent: () =>
      import(
        './core/layouts/admin-layouts/admin-wrapper/admin-wrapper.component'
      ).then((c) => c.AdminWrapperComponent),
    loadChildren: () =>
      import('./pages/admin-pages/admin-pages.routes').then(
        (r) => r.adminPagesRoutes
      ),
  },
  {
    path: 'web',
    title: 'Top Notch Dezigns® Hub',
    canActivate: [isLoginGuard, isWebGuard],
    loadComponent: () =>
      import(
        './core/layouts/web-layouts/admin-wrapper/admin-wrapper.component'
      ).then((c) => c.AdminWrapperComponent),
    loadChildren: () =>
      import('./pages/web-pages/web-pages.routes').then(
        (r) => r.webPagesRoutes
      ),
  },
  {
    path: 'enrollment-form',
    title: 'Top Notch Dezigns® Hub',
    canActivate: [isLoginGuard, isEnrollGuard],
    loadChildren: () =>
      import('./pages/enrollment-form/enrollment-form-pages.routes').then(
        (m) => m.enrollmentFormPagesRoutes
      ),
  },
  {
    path: 'enrollment-form-preview/:id',
    title: 'Top Notch Dezigns® Hub',
    canActivate: [isLoginGuard],
    loadChildren: () =>
      import('./pages/enrollment-form/enrollment-form-pages.routes').then(
        (m) => m.enrollmentFormPagesRoutes
      ),
  },
  {
    path: 'redirects',
    loadChildren: () =>
      import('./pages/redirect/redirect.route').then((m) => m.adminPagesRoutes),
  },
  {
    path: 'forbidden',
    title: 'Forbidden',
    loadChildren: () =>
      import(
        './pages/admin-pages/components/forbidden-page/forbidden.route'
      ).then((r) => r.forbiddenPageRoute),
  },
  {
    path: 'not-found',
    title: 'Not-Found',
    loadChildren: () =>
      import(
        './pages/admin-pages/components/not-found-page/not-found.route'
      ).then((m) => m.notFoundPageRoute),
  },
  {
    path: '**',
    redirectTo: '/not-found',
  },
];
