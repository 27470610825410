import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import {
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { AppState } from './store';
import { environment } from '@env/environment';
import { NgxsModule } from '@ngxs/store';
import { SettingsState } from './store/state/settings.state';
import { AccessControlState } from './store/state/access-control.state';
import { provideToastr } from 'ngx-toastr';
import { UserManagementState } from './store/state/user-management.state';
import { DripState } from './store/state/drip.state';
import { EvaluationState } from './store/state/evaluation.state';
import {
  httpErrorHandlerInterceptorFn,
  httpHeaderHandlerInterceptorFn,
  httpSuccessHandlerInterceptorFn,
} from './core/interceptors';
import { ClientManagementState } from './store/state/client-management.state';
import {
  GoogleLoginProvider,
  MicrosoftLoginProvider,
  SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';
import { WebsiteManagementState } from './store/state/website-management.state';
import { ProfilesState } from './store/state/profiles.state';
import { EnrollmentState } from './store/state/enrollment.state';
import { HttpProviders } from './http.providers';
import { ManageMemberState } from './store/state/website-state/manage-members.state';
import { ManageProjectState } from './store/state/website-state/manage-project.state';
import { DashboardState } from './store/state/website-state/dashboard.state';

const STATES: any = [
  AppState,
  SettingsState,
  AccessControlState,
  UserManagementState,
  DripState,
  EvaluationState,
  ClientManagementState,
  WebsiteManagementState,
  ProfilesState,
  EnrollmentState,
  DashboardState,
  ManageMemberState,
  ManageProjectState,
]; // [AppState];

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
      })
    ),
    importProvidersFrom(
      HttpClientModule,
      FormsModule,
      ReactiveFormsModule,
      NgxsModule.forRoot([...STATES], {
        developmentMode: !environment.production,
      })
    ),
    HttpProviders,
    provideToastr({
      closeButton: true,
      positionClass: 'toast-top-right',
      timeOut: 3000,
    }),
    provideAnimationsAsync(),
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        lang: 'en',
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.google_client_id),
          },
          {
            id: MicrosoftLoginProvider.PROVIDER_ID,
            provider: new MicrosoftLoginProvider(
              environment.microsoft_client_id
              // {
              //   redirect_uri: 'http://localhost:4200',
              //   logout_redirect_uri: 'http://localhost:4200/logout',
              // }
            ),
          },
          // {
          //   id: FacebookLoginProvider.PROVIDER_ID,
          //   provider: new FacebookLoginProvider('clientId'),
          // },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
  ],
};
