import {
  HttpEvent,
  HttpRequest,
  HttpHandlerFn,
  HttpInterceptorFn,
} from '@angular/common/http';
import { Inject, inject } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Observable, catchError, finalize, throwError } from 'rxjs';
import { AuthenticationService } from '../authentication';
import { AclService } from '../services';
import { Router } from '@angular/router';

export const httpRequestAClHandlerInterceptorFn: HttpInterceptorFn = (
  request: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const loadingBar = inject(LoadingBarService);
  const aclService = inject(AclService);
  const router = inject(Router);

  /** Getting Request Body and Url */
  let apiUrl = request.url.split('v1/');
  let actionArr = aclService.actionIdArr;
  let param: any = request.body;

  /** Updating Request Body with action Id */
  actionArr.forEach((item) => {
    if (item.url === apiUrl[1]) {
      if (param.type && param.type === item.type) {
        param['action_id'] = item.actionId;
      } else if (!param.type) {
        param['action_id'] = item.actionId;
      }
    }
  });

  // actionArr.forEach((item) => {
  //   if (item.url === apiUrl[1]) {
  //     if (param.type && param.type === item.type) {
  //       param['action_id'] = item.actionId;
  //     } else if (!param.type) {
  //       param['action_id'] =
  //         router.routerState.snapshot.url == '/employee-onboarding'
  //           ? 76
  //           : item.actionId;
  //     }
  //   }
  // });

  /** Pushing new Request Body to the request */
  const HTTPRequest = request.clone({
    body: param,
  });

  loadingBar.useRef().start();
  return next(HTTPRequest).pipe(
    finalize(() => loadingBar.useRef().complete()),
    catchError((error: any) => {
      loadingBar.useRef().complete();
      return throwError(() => error);
    })
  );
};
