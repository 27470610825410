import { Component, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { LoadingBarModule, LoadingBarService } from '@ngx-loading-bar/core';
import { Subscription } from 'rxjs';
import { CommonService } from './core/services';
import { PreloaderComponent } from './shared/components/preloader/preloader.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, LoadingBarModule, PreloaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'angular_v1';
  private currentURL = '';
  private subscriptions: Subscription[] = [];
  constructor(
    private _router: Router,
    private _common: CommonService,
    private _loadingBar: LoadingBarService
  ) {}

  ngOnInit(): void {
    this.handleLoadingState();
  }

  private handleLoadingState() {
    this.subscriptions.push(
      this._router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          const URl = event.url;
          if (
            URl.split('?')[0].split('#')[0] !==
            this.currentURL.split('?')[0].split('#')[0]
          ) {
            this._common.setLoadingStatus(true);
          }
          this.currentURL = event.url;
          this._loadingBar.useRef().complete();
        }
        if (
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this._common.setLoadingStatus(false);
        }
      })
    );
  }
}
