import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FetchAllListCount } from '../actions/app.action';
import { HttpService } from '@app/core/http';
import { tap } from 'rxjs';
import { Injectable } from '@angular/core';

interface IAppStateModel {
  allListCount: IAllListCounts | null;
}

@State<IAppStateModel>({
  name: 'AppState',
  defaults: {
    allListCount: null,
  },
})
@Injectable()
export class AppState {
  constructor(private _http: HttpService) {}

  @Selector()
  static allListCount(state: IAppStateModel) {
    return state.allListCount;
  }

  @Action(FetchAllListCount)
  FetchAllListCount(ctx: StateContext<IAppStateModel>) {
    return this._http.post('user/all_list_count', {}).pipe(
      tap((apiResult) => {
        ctx.patchState({ allListCount: { ...apiResult.response.dataset } });
      })
    );
  }
}
