import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { Router, UrlTree, CanActivateFn } from '@angular/router';
import { appSettings } from '@app/configs';
import { CookieService } from 'ngx-cookie-service';

export const isEnrollGuard: CanActivateFn = ():
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  /**
   * *Auth Guard for prevent user to visit before login pages
   *
   *
   */
  const _router: Router = inject(Router);
  const _cookieService: CookieService = inject(CookieService);
  const storedData: string = _cookieService.get(appSettings.credentialsKey);
  const credentialObject: ILoginAuthResponse = JSON.parse(storedData);

  if (
    credentialObject.is_enrolled == 1 ||
    (credentialObject.user_type !== 3 && credentialObject.is_enrolled == 0)
  ) {
    _router.navigate(['/login']);
    return false;
  }
  return true;
};
